import React from "react";
import { Link } from 'gatsby';
import Hours from "../hours";
import FixedImage from "../fixedImage";
import formatPhone from "../../helpers/formatPhone";
import useModalState from '../../helpers/modalState';


import * as styleSheet from "./index.module.scss"

const LocationCard = ({ location, arrowRedLocal, rightArrowGreenOffsite, rightArrowYellowOffsite, rightArrowGray = false, isTeamPage = false, isAppointmentsPage = false }) => {

	const [showAppointmentModal, setShowAppointmentModal] = useModalState();

	function showModal(zipcode){
		  if( !showAppointmentModal.modalOpen ){
			if(zipcode) {
				setShowAppointmentModal({modalOpen: true, zipcode: zipcode});
			} else {
				setShowAppointmentModal({modalOpen: true});
			}
		  }
	  }
  
	function handleApptKeyDown(e) {
	  if (e.keyCode === 13 || e.keyCode === 32) {
			  setShowAppointmentModal({modalOpen: true});
		  }
	}

	return (
		<div className={isTeamPage ? styleSheet.LocationCardTeamPage : styleSheet.LocationCard}>
			<div className={styleSheet.top}>
				{!isAppointmentsPage && <Link to={location.link} className={styleSheet.title} dangerouslySetInnerHTML={{ __html: location.title }}></Link>}
				{isAppointmentsPage && <Link to={location.link} className={styleSheet.titleappointments} dangerouslySetInnerHTML={{ __html: location.title }}></Link>}
				
				{location?.birdeyeLocation?.hoursOfOperations && !isAppointmentsPage && <Hours locationHours={location.birdeyeLocation.hoursOfOperations} utcOffset={location.birdeyeLocation.timezone} locationTitle={location.title} />}
			</div>
			<div className={styleSheet.bottom}>
				{location?.ACFLocation?.briefDescription && <p dangerouslySetInnerHTML={{ __html: location.ACFLocation.briefDescription.length > 100 ? location.ACFLocation.briefDescription.substring(0, 99) + "..." : location.ACFLocation.briefDescription }}></p>}
				{location?.birdeyeLocation?.location?.address1 && <span className={styleSheet.address} dangerouslySetInnerHTML={{ __html: location.birdeyeLocation.location.address1 }}></span>}
				{location?.birdeyeLocation?.location?.address2 && <span className={styleSheet.address} dangerouslySetInnerHTML={{ __html: location.birdeyeLocation.location.address2 }}></span>}
				{location?.birdeyeLocation?.location?.zip && location.birdeyeLocation.location.state && location.birdeyeLocation.location.city && <span className={styleSheet.address} dangerouslySetInnerHTML={{ __html: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state} ${location.birdeyeLocation.location.zip}` }}></span>}
				{location?.birdeyeLocation.location.lat && location.birdeyeLocation.location.lng && <a className={styleSheet.directions} href={`https://www.google.com/maps/dir/?api=1&destination=${location.birdeyeLocation.location.lat},${location.birdeyeLocation.location.lng}`} target="_blank" rel="noopener noreferrer">Get Directions <FixedImage image={rightArrowGreenOffsite} passedClass={styleSheet.icon} /></a>}
				{location?.birdeyeLocation?.phone && <a className={styleSheet.phone} href={`tel:${location.birdeyeLocation.phone}`}>{formatPhone(location.birdeyeLocation.phone)}</a>}

				{!isAppointmentsPage && !location?.ACFLocation?.bookMyAppointmentLink && location?.birdeyeLocation?.location?.zip && <button onClick={() => showModal(location.birdeyeLocation.location.zip)} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}> Book Your First Visit <FixedImage image={arrowRedLocal} passedClass={styleSheet.icon} /></button>}
				{!isAppointmentsPage && !location?.ACFLocation?.bookMyAppointmentLink && !location?.birdeyeLocation?.location?.zip && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Book Your First Visit <FixedImage image={arrowRedLocal} passedClass={styleSheet.icon} /></button>}

				{isAppointmentsPage && !location?.ACFLocation?.bookMyAppointmentLink && location?.birdeyeLocation?.location?.zip && <button onClick={() => showModal(location.birdeyeLocation.location.zip)} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}> Book Now</button>}
				{isAppointmentsPage && !location?.ACFLocation?.bookMyAppointmentLink && !location?.birdeyeLocation?.location?.zip && <button onClick={showModal} onKeyDown={(e) => handleApptKeyDown(e)} className={styleSheet.link} aria-expanded={showAppointmentModal.modalOpen ? true : false}>Book Now</button>}
			</div>
		</div>
	);
}

export default LocationCard;
